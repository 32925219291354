import { useState } from "react";
import { createContext, useContext, useContextSelector } from "use-context-selector";

const SidebarContext = createContext({});

/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
const useSidebarContextVals = () => {
  const [open, setOpen] = useState(false);

  return {
    sidebarOpen: open,
    setSidebarOpen: setOpen,
  };
};

const SidebarContextProvider = (props) => {
  // This hook has side effects of adding listeners so we only want to create it
  // once and store it in context for reference by components.
  const sidebarContext = useSidebarContextVals();

  return <SidebarContext.Provider value={{ ...sidebarContext }}>{props.children}</SidebarContext.Provider>;
};

/**
 * The current context value for the window size context.
 * This value updates whenever the window is resized.
 *
 * Use this inside a {@link WindowSizeContextProvider}.
 *
 * @type number
 */
const useSidebarContext = () => useContext(SidebarContext);
const useSidebarSelectorContext = (selector: any) => {
  return useContextSelector(SidebarContext, selector);
};
export { SidebarContextProvider, useSidebarContext, useSidebarSelectorContext };
