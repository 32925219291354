import { ButtonTypeEnum, IMessage, PopoverNotificationCenter } from "@novu/notification-center";
import { useNavigate } from "@remix-run/react";
import { ReactNode, useRef, useState } from "react";
import OnboardingSession from "~/modules/onboarding/components/OnboardingSession";
import { useAppOrAdminData } from "~/utils/data/useAppOrAdminData";
import AddFeedbackButton from "./buttons/AddFeedbackButton";
import ChatSupportButton from "./buttons/ChatSupportButton";
import CurrentSubscriptionButton from "./buttons/CurrentSubscriptionButton";
import LinkedAccountsButton from "./buttons/LinkedAccountsButton";
import NotificationsButton from "./buttons/NotificationsButton";
import OnboardingButton from "./buttons/OnboardingButton";
import ProfileButton from "./buttons/ProfileButton";
import QuickActionsButton from "./buttons/QuickActionsButton";
import SaasRockUpdateButton from "./buttons/SaasRockUpdateButton";
import SearchButton from "./buttons/SearchButton";

interface Props {
  layout: "app" | "admin" | "docs";
  children: ReactNode;
  onOpenCommandPalette: () => void;
  sideTitle: string;
  sideContents: any;
  side?: string;
  asideWidthClass?: string;
  breakpointClass?: string;
}
export default function SidebarLayout({ layout, children }: Props) {
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);

  const mainElement = useRef<HTMLElement>(null);


  return (
    <NotificationsProvider layout={layout}>
      <OnboardingSession open={onboardingModalOpen} setOpen={setOnboardingModalOpen} />
      {/* <WarningBanner title="Onboarding" text={appOrAdminData.onboarding?.onboarding.title ?? "No onboarding"} /> */}
      {children}
      {/*  */}
    </NotificationsProvider>
  );
}

function NotificationsProvider({ layout, children }: { layout: "app" | "admin" | "docs"; children: ReactNode }) {
  // const rootData = useRootData();
  // const appOrAdminData = useAppOrAdminData();
  // function hasNotifications() {
  //   if (layout === "docs") {
  //     return false;
  //   }
  //   return rootData.appConfiguration.notifications.enabled && rootData.appConfiguration.notifications.novuAppId !== undefined;
  // }
  return children;
  // return (
  //   <>
  //     {hasNotifications() ? (
  //       <NovuProvider subscriberId={appOrAdminData.user?.id} applicationIdentifier={rootData.appConfiguration.notifications.novuAppId ?? ""}>
  //         {children}
  //       </NovuProvider>
  //     ) : (
  //       children
  //     )}
  //   </>
  // );
}

function NavBar({
  layout,
  title,
  buttons,
  onOpenCommandPalette,
  onOpenOnboardingModal,
}: {
  layout?: string;
  title?: string;
  buttons: {
    mySubscription: boolean;
    linkedAccounts: boolean;
    feedback: boolean;
    chatSupport: boolean;
    search: boolean;
    notifications: boolean;
    onboarding: boolean;
    hasUpdate: boolean;
  };
  onOpenCommandPalette: () => void;
  onOpenOnboardingModal: () => void;
}) {
  const appOrAdminData = useAppOrAdminData();
  const navigate = useNavigate();
  function onNotificationClick(notification: IMessage) {
    if (notification.cta.data.url) {
      if (notification.cta.data.url.startsWith("http")) {
        if (window !== undefined) {
          window.location.href = notification.cta.data.url;
        }
      } else {
        navigate(notification.cta.data.url);
      }
    }
  }
  function onActionClick(_templateIdentifier: string, _type: ButtonTypeEnum, _message: IMessage) {
    // TODO
  }
  return (
    <div className="flex flex-1 justify-between space-x-2 px-3">
      <div className="flex flex-1 items-center">
        <div className="font-extrabold">{title}</div>
      </div>
      <div className="flex items-center space-x-2 md:ml-6">
        {buttons.onboarding && appOrAdminData.onboardingSession && <OnboardingButton item={appOrAdminData.onboardingSession} onClick={onOpenOnboardingModal} />}
        {layout === "app" && buttons.mySubscription && <CurrentSubscriptionButton />}
        {layout === "admin" && buttons.hasUpdate && <SaasRockUpdateButton />}
        {buttons.notifications && (
          <PopoverNotificationCenter onNotificationClick={onNotificationClick} onActionClick={onActionClick} colorScheme={"light"}>
            {({ unseenCount }) => <NotificationsButton unseenCount={unseenCount} />}
          </PopoverNotificationCenter>
        )}
        {buttons.search && <SearchButton onClick={onOpenCommandPalette} />}
        {layout === "app" && buttons.feedback && <AddFeedbackButton />}
        {layout === "app" && buttons.linkedAccounts && <LinkedAccountsButton />}
        {layout === "app" && buttons.chatSupport && <ChatSupportButton />}
        {layout === "app" && <QuickActionsButton entities={appOrAdminData.entities.filter((f) => f.showInSidebar)} />}
        {(layout === "app" || layout === "admin") && <ProfileButton user={appOrAdminData.user} layout={layout} />}
      </div>
    </div>
  );
}
